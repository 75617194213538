import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareIconBar, {
  EasySoftwareIconBarType,
} from '../EasySoftwareIconBar/EasySoftwareIconBar'

export interface WidgetEasySoftwareIconBarType
  extends WidgetBase,
  EasySoftwareIconBarType {
  contentType: 'EasySoftwareIconBar'
}

const WidgetEasySoftwareIconBar: FunctionComponent<WidgetEasySoftwareIconBarType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <EasySoftwareIconBar
      displayText={props.displayText}
      iconBarIconList={props.iconBarIconList}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareIconBar
